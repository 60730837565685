var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-8",
    attrs: {
      "outlined": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-tabs', {
    attrs: {
      "background-color": "#12233310"
    },
    model: {
      value: _vm.tabSelected,
      callback: function ($$v) {
        _vm.tabSelected = $$v;
      },
      expression: "tabSelected"
    }
  }, _vm._l(_vm.allPlans, function (plan) {
    return _c('v-tab', {
      key: plan.internal,
      staticClass: "text-h6",
      attrs: {
        "active-class": "ctab-active"
      }
    }, [_vm._v(" " + _vm._s(plan.title) + " ")]);
  }), 1), _c('v-tabs-items', {
    staticClass: "pt-5",
    model: {
      value: _vm.tabSelected,
      callback: function ($$v) {
        _vm.tabSelected = $$v;
      },
      expression: "tabSelected"
    }
  }, _vm._l(_vm.allPlans, function (plan) {
    return _c('v-tab-item', {
      key: plan.internal
    }, [plan.internal !== 'FREE' ? _c('div') : _c('div', {
      staticClass: "text-h4 font-weight-bold py-5"
    }, [_vm._v(" " + _vm._s(plan.price) + " ")]), _c('v-row', [_c('v-col', {
      staticClass: "pb-8",
      attrs: {
        "cols": "8"
      }
    }, _vm._l(plan.features, function (feat, i) {
      return _c('v-checkbox', {
        key: feat.html,
        attrs: {
          "readonly": "",
          "color": feat.active ? 'primary' : '',
          "value": feat.active,
          "disabled": !feat.active,
          "hide-details": ""
        },
        scopedSlots: _vm._u([{
          key: "label",
          fn: function () {
            return [_c('div', {
              domProps: {
                "innerHTML": _vm._s(feat.html)
              }
            })];
          },
          proxy: true
        }], null, true),
        model: {
          value: feat.active,
          callback: function ($$v) {
            _vm.$set(feat, "active", $$v);
          },
          expression: "feat.active"
        }
      });
    }), 1), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [plan.internal === 'FREE' ? _c('ul', {
      staticClass: "text-body-1"
    }, _vm._l(_vm.commonFeatures, function (f) {
      return _c('li', {
        key: f
      }, [_vm._v(" " + _vm._s(f) + " ")]);
    }), 0) : _vm._e(), plan.internal === 'SCALE' && !_vm.hasPremium ? _c('div', [_c('div', _vm._l(_vm.addons, function (addon, idx) {
      return _c('v-checkbox', {
        key: addon.id,
        attrs: {
          "label": addon.name,
          "value": addon,
          "hide-details": ""
        },
        scopedSlots: _vm._u([{
          key: "label",
          fn: function () {
            return [_c('div', [_c('span', [_vm._v(" " + _vm._s(addon.name) + ": ")]), _c('span', {
              staticClass: "font-weight-bold primary--text"
            }, [_vm._v(" $" + _vm._s(addon.price) + " ")])])];
          },
          proxy: true
        }], null, true),
        model: {
          value: _vm.selectedAddons,
          callback: function ($$v) {
            _vm.selectedAddons = $$v;
          },
          expression: "selectedAddons"
        }
      });
    }), 1)]) : _vm._e(), plan.internal === 'SCALE' && _vm.hasPremium ? _c('div', [_c('div', {
      staticClass: "text-h6"
    }, [_vm._v("Premium Plan is active")]), _vm._l(_vm.addons, function (addon) {
      return _c('div', {
        key: addon.id,
        staticClass: "text-body-2"
      }, [_vm.isAddonSubscribed(addon) ? _c('v-checkbox', {
        attrs: {
          "input-value": true,
          "color": "primary",
          "readonly": "",
          "hide-details": ""
        },
        scopedSlots: _vm._u([{
          key: "label",
          fn: function () {
            return [_c('div', [_c('span', [_vm._v(" " + _vm._s(addon.name) + ": ")]), _c('span', {
              staticClass: "font-weight-bold primary--text"
            }, [_vm._v(" $" + _vm._s(addon.price) + " ")])])];
          },
          proxy: true
        }], null, true)
      }) : _vm._e()], 1);
    })], 2) : _vm._e()])], 1), _c('v-row', {
      staticClass: "d-flex flex-row-reverse pa-8 font-weight-medium"
    }, [_vm._v(" Email to hello@codedrills.io to activate your plan ")])], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }