<template>
  <v-card outlined elevation="0" class="px-8">
    <!-- <v-card-title class="px-0 py-5 ma-0 d-flex justify-space-between">
      <span class="text-h4 font-weight-bold"> Plans </span>
      <v-btn
        v-if="hiringPlan !== 'FREE'"
        class="text-none text-body-1"
        color="primary"
        @click="handleManageSub"
        :loading="isManaging"
      >
        See Billing Details
      </v-btn>
    </v-card-title> -->
    <v-card-text class="pa-0 ma-0">
      <v-tabs v-model="tabSelected" background-color="#12233310">
        <v-tab
          v-for="plan in allPlans"
          :key="plan.internal"
          active-class="ctab-active"
          class="text-h6"
        >
          {{ plan.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabSelected" class="pt-5">
        <v-tab-item v-for="plan in allPlans" :key="plan.internal">
          <div v-if="plan.internal !== 'FREE'">
            <!-- <div class="d-flex justify-space-between" v-if="!hasPremium">
              <div class="text-h4 font-weight-bold py-5" v-if="annually">
                ${{ totalDisplayPrice }}/year,
                <span class="text-body-1">billed annually</span>
              </div>
              <div class="text-h4 font-weight-bold py-5" v-if="!annually">
                ${{ totalDisplayPrice }}/month,
                <span class="text-body-1">billed monthly</span>
              </div>
              <v-checkbox
                v-model="annually"
                label="Billed Annually"
              ></v-checkbox>
            </div> -->
          </div>
          <div class="text-h4 font-weight-bold py-5" v-else>
            {{ plan.price }}
          </div>
          <v-row>
            <v-col cols="8" class="pb-8">
              <v-checkbox
                v-for="(feat, i) in plan.features"
                :key="feat.html"
                readonly
                v-model="feat.active"
                :color="feat.active ? 'primary' : ''"
                :value="feat.active"
                :disabled="!feat.active"
                hide-details
              >
                <template v-slot:label>
                  <div v-html="feat.html"></div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="4" class="">
              <ul class="text-body-1" v-if="plan.internal === 'FREE'">
                <li v-for="f in commonFeatures" :key="f">
                  {{ f }}
                </li>
              </ul>
              <div v-if="plan.internal === 'SCALE' && !hasPremium">
                <!-- addons selector when subbing to a plan -->
                <div>
                  <v-checkbox
                    v-for="(addon, idx) in addons"
                    :key="addon.id"
                    :label="addon.name"
                    :value="addon"
                    hide-details
                    v-model="selectedAddons"
                  >
                    <template v-slot:label>
                      <div>
                        <span> {{ addon.name }}: </span>
                        <span class="font-weight-bold primary--text">
                          ${{ addon.price }}
                        </span>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </div>
              <div v-if="plan.internal === 'SCALE' && hasPremium">
                <!-- list of subbed addons -->
                <!-- <div class="text-h6">Subscribed Addons</div> -->
                <div class="text-h6">Premium Plan is active</div>
                <div
                  v-for="addon in addons"
                  :key="addon.id"
                  class="text-body-2"
                >
                  <v-checkbox
                    v-if="isAddonSubscribed(addon)"
                    :input-value="true"
                    color="primary"
                    readonly
                    hide-details
                  >
                    <template v-slot:label>
                      <div>
                        <span> {{ addon.name }}: </span>
                        <span class="font-weight-bold primary--text">
                          ${{ addon.price }}
                        </span>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row
            v-if="plan.internal !== 'FREE'"
            class="d-flex flex-row-reverse pa-8"
          >
            <v-btn
              class="text-none text-body-1 font-weight-medium"
              @click="handleSelectPlan"
              :loading="planSelected"
              :color="!hasPremium ? 'primary' : ''"
              v-if="!hasPremium"
            >
              Select Plan
            </v-btn>
          </v-row> -->
          <v-row class="d-flex flex-row-reverse pa-8 font-weight-medium">
            Email to hello@codedrills.io to activate your plan
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default {
  props: {},
  data() {
    return {
      tabSelected: 0,
      viewingPlan: null,
      planSelected: false,
      annually: false,
      isManaging: false,
      totalDisplayPrice: 0,
      subscribedPrice: 0,
      allPlans: [
        {
          price: 'Free',
          title: 'Free',
          internal: 'FREE',
          features: [
            { active: true, html: '<b>5 Candidates' },
            { active: true, html: '<b>1</b> Assessments' },
            { active: true, html: '<b>3</b> Admins' },
            { active: true, html: '<b>Sample</b> Skills' },
            // { active: true, html: "<b>1</b> Custom question / Assessment" },
            {
              active: true,
              html: 'Multiple Question Types -  MCQ, Coding and Subjective',
            },
          ],
        },
        {
          price: 199,
          title: 'Premium',
          internal: 'SCALE',
          features: [
            { active: true, html: '<b>Custom number</b> of Candidates' },
            { active: true, html: '<b>Custom number</b> of Assessments' },
            { active: true, html: '<b>Custom number</b> of Admins' },
            {
              active: true,
              html: '<b>100+</b> Standard/Custom Skills Library',
            },
            { active: true, html: 'Candidate Pipeline' },
            { active: true, html: 'Proctoring' },
            { active: true, html: 'Detailed Test Report' },
            // { active: true, html: 'Limited Live Code Pairing with Audio' },
            { active: true, html: 'Account Roles and Permission' },
            { active: true, html: 'Centralized Billing' },
            { active: true, html: 'Analytics Dashboard' },
            // { active: true, html: '<b>5</b> Custom question / Assessment' },
            {
              active: true,
              html: 'Multiple Question Types: MCQ, Coding, Subjective',
            },
            { active: true, html: 'Priority Support via Chat/Email' },
          ],
        },
      ],
      addons: [
        // { name: 'Unlimited Admins', id: 'ADD_ON_UNLIMITED_ADMINS', price: 100 },
        // {
        //   name: 'Exclusive Skills Library',
        //   id: 'ADD_ON_EXCLUSIVE_SKILLS_LIBRARY',
        //   price: 100,
        // },
        // { name: 'Bulk Operations', id: 'ADD_ON_BULK_OPERATIONS', price: 100 },
        // {
        //   name: 'Advanced Proctoring',
        //   id: 'ADD_ON_ADVANCED_PROCTORING',
        //   price: 100,
        // },
      ],
      selectedAddons: [],
      commonFeatures: [
        'Account roles and permissions',
        'Candidate pipeline',
        'Proctoring',
        'Detailed test report',
      ],
    }
  },
  computed: {
    ...mapGetters('customer', ['hiringPlanKeys', 'prettyHiringPlans']),
    ...mapGetters('recruiter', ['addonKeys']),
    ...mapState('customer', ['customer']),
    ...mapState('recruiter', ['manageSubUrl', 'checkoutUrl']),
    hiringPlan() {
      return this.hiringPlanKeys[this.customer?.getHiringPlan()]
    },
    hasPremium() {
      return this.hiringPlan === 'SCALE'
    },
    planPrice() {
      if (this.annually) {
        return this.allPlans[this.tabSelected].price * 12
      } else {
        return this.allPlans[this.tabSelected].price
      }
    },
    shouldCheckout() {},
    subscribedAddons() {
      if (this.hasPremium) {
        return this.customer?.getAddOns()?.getAddOnList()
      }
    },
    addonIds() {
      return this.subscribedAddons.map((addon) => {
        return this.addonKeys[addon]
      })
    },
    subscribedAnnually() {
      // @TODO: check and show total billing price
    },
  },
  components: {},
  methods: {
    ...mapActions('recruiter', ['initiateCheckout', 'manageSubscription']),
    handleSelectPlan() {
      this.planSelected = true
      if (this.hiringPlan === 'FREE' && this.viewingPlan?.internal !== 'FREE') {
        // moving from free
        const frequency = this.annually ? 'ANNUAL' : 'MONTH'
        console.log(`freq`, frequency)
        this.initiateCheckout({
          planType: this.viewingPlan?.internal,
          planFreq: frequency,
          addonsList: this.selectedAddons,
        })
          .then((r) => {
            window.location.href = this.checkoutUrl
          })
          .catch((err) => {
            this.$store.dispatch('notifs/addNotif', {
              text:
                err.message ||
                `Error during checkout, please try again after sometime!`,
              type: 'error',
            })
            this.planSelected = false
          })
      }
    },
    handleManageSub() {
      this.isManaging = true
      this.manageSubscription().then((r) => {
        // window.location.href = this.manageSubUrl;
        window.open(this.manageSubUrl, 'Manage Subscription | CodeDrills')
        this.isManaging = false
      })
    },
    recalculatePrice() {
      this.totalDisplayPrice = this.planPrice
      this.selectedAddons.forEach((addon) => {
        if (this.annually) {
          this.totalDisplayPrice += addon.price * 12
        } else {
          this.totalDisplayPrice += addon.price
        }
      })
    },
    isAddonSubscribed(addon) {
      return this.addonIds.includes(addon.id)
    },
    selectTab() {
      if (this.hasPremium) {
        this.tabSelected = 1
      } else {
        this.tabSelected = 0
      }
    },
  },
  created() {
    // initially
    this.viewingPlan = this.allPlans[this.tabSelected]
    this.totalDisplayPrice = this.allPlans[this.tabSelected]
  },
  watch: {
    tabSelected: function (n, o) {
      // 0 free, 1 premium, based on v-tab's v-model
      this.viewingPlan = this.allPlans[this.tabSelected]
      this.totalDisplayPrice = this.planPrice
    },
    planPrice() {
      this.recalculatePrice()
    },
    annually() {
      this.recalculatePrice()
    },
    selectedAddons() {
      this.recalculatePrice()
    },
    hiringPlan: {
      handler() {
        this.selectTab()
      },
      immediate: true,
    },
  },
}
</script>
<style scoped>
.ctab-active {
  color: #122333;
  background-color: white;
}
</style>
